import React from 'react'

import Layout from '../components/layout'
import Svg from '../components/svg'
import TextareaAutosize from 'react-textarea-autosize'
import Reveal from '../components/reveal'

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
}

export default ({ location }) => {
    const [state, setState] = React.useState({ type: 'hello' })

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state
            })
        })
            .then(() => {
                setState({ type: state.type })
                if (typeof document != 'undefined') {
                    document
                        .querySelectorAll(
                            '[type="email"],[type="text"],textarea'
                        )
                        .forEach(el => {
                            el.value = ''
                        })
                }
                alert('Thank you!')
            })
            .catch(error => alert(error))
    }

    return (
        <Layout location={location} title="Contact">
            <section className="section section-hero">
                <div className="wrap">
                    <Reveal>
                        <h1 className="label">Contact</h1>
                        <h2 className="title">
                            Ready to Take Your Business to the Next Level? We’re
                            Ready to Talk.
                        </h2>
                    </Reveal>
                    <form
                        name="contact"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        <Reveal className="input-radio">
                            <div>You are here for</div>
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    checked={state.type === 'hello'}
                                    value="hello"
                                    onChange={handleChange}
                                />
                                <Svg name="check" className="check" />
                                <Svg name="checked" className="checked" />
                                <span>Say Hello</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    checked={state.type === 'project'}
                                    value="project"
                                    onChange={handleChange}
                                />
                                <Svg name="check" className="check" />
                                <Svg name="checked" className="checked" />
                                <span>Project inquiry</span>
                            </label>
                        </Reveal>
                        <div className="form-flex">
                            <Reveal className="input">
                                <label htmlFor="name">Name and Last Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    onChange={handleChange}
                                    placeholder="Name and Last Name"
                                />
                            </Reveal>
                            <Reveal className="input">
                                <label htmlFor="phone">Phone number</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    required
                                    onChange={handleChange}
                                    placeholder="Phone number"
                                />
                            </Reveal>
                            <Reveal className="input">
                                <label htmlFor="email">E-mail</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    onChange={handleChange}
                                    placeholder="E-mail"
                                />
                            </Reveal>
                            <Reveal className="input">
                                <label htmlFor="company">Business name</label>
                                <input
                                    type="text"
                                    id="company"
                                    name="company"
                                    required
                                    onChange={handleChange}
                                    placeholder="Business name"
                                />
                            </Reveal>
                        </div>
                        {state.type === 'hello' ? (
                            <Reveal className="input input-full">
                                <label htmlFor="text">Say Hello</label>
                                <TextareaAutosize
                                    name="text"
                                    id="text"
                                    rows="1"
                                    required
                                    onChange={handleChange}
                                    placeholder="Hello, I’am John and I would like to talk to you guys about..."
                                />
                            </Reveal>
                        ) : (
                            <div className="form-flex">
                                <Reveal className="input">
                                    <label htmlFor="existing_website">
                                        Existing website
                                    </label>
                                    <input
                                        type="text"
                                        id="existing_website"
                                        name="existing_website"
                                        placeholder="Existing website"
                                        onChange={handleChange}
                                        required
                                    />
                                </Reveal>
                                <Reveal className="input">
                                    <label htmlFor="time_frame">
                                        What is your time frame
                                    </label>
                                    <input
                                        type="text"
                                        id="time_frame"
                                        name="time_frame"
                                        placeholder="Number of weeks"
                                        onChange={handleChange}
                                        required
                                    />
                                </Reveal>
                                <Reveal className="input">
                                    <label htmlFor="budget">
                                        What is your budget?
                                    </label>
                                    <input
                                        type="text"
                                        id="budget"
                                        name="budget"
                                        placeholder="$"
                                        onChange={handleChange}
                                        required
                                    />
                                </Reveal>
                                <Reveal className="input">
                                    <label htmlFor="about_project">
                                        Tell us about your project
                                    </label>
                                    <input
                                        type="text"
                                        id="about_project"
                                        name="about_project"
                                        placeholder="Tell us about your project"
                                        onChange={handleChange}
                                        required
                                    />
                                </Reveal>
                            </div>
                        )}

                        <button type="submit" className="button">
                            Send
                        </button>
                    </form>
                </div>
            </section>
        </Layout>
    )
}
